* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
}

.scan-result {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Add padding to avoid content touching the edges */
    margin: auto; /* Optional: if you want it centered in a larger container */
    text-align: center; /* Center the text inside the div */
    max-width: 600px; /* Optional: Set a max-width for the result container */
    /*background-color: #f9f9f9;  Optional: Background color */
    border-radius: 8px; /* Optional: Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Shadow for better UI */
}

/* Container for the token cards */
.token-results-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 15px;
    padding: 10px;
}

/* Card for each token */
.token-card {
    background: transparent;
    border-radius: 10px;
    border: 1px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
}

/* Token logo styling */
.token-logo img {
    border-radius: 50%;
    margin-bottom: 10px;
}

/* Token info */
.token-info {
    margin-top: 10px;
}

/* Ensure text is properly aligned and readable */
.token-info TelegramText {
    font-size: 14px;
    color: #333;
    margin: 5px 0;
}
